<!--
 * @Author: wxb
 * @Date: 2020-06-28 17:16:12
 * @LastEditTime: 2021-06-29 12:07:53
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\components\noData.vue
-->
<template>
  <div class="nodata">
    <div class="nodata-content">
      <div class="nodata-content-tips">{{tips}}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tips: {
      type: String,
      default: '未查到相关信息'
    }
  }
}
</script>

<style lang="scss" scoped>
$contentWidth: 1200px;
.nodata {
  width: $contentWidth;
  margin: 20px auto;
  font-size: 25px;
  text-align: center;
  padding: 40px 0 230px;
  &-content {
    width: 81rem;
    height: 44rem;
    background: url("../assets/nodata.png");
    margin: 0 auto;
    position: relative;
    &-tips {
      font-size: 1.8rem;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      position: absolute;
      bottom: 44px;
      width: 100%;
    }
  }
}
</style>
