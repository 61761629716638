/*
 * @Author: wxb
 * @Date: 2021-07-13 14:10:02
 * @LastEditTime: 2021-08-11 18:00:50
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\api\subject\index.js
 */
import request from '@/utils/request'
import qs from 'qs'
/**
 * @description: 获取得到行业树形信息
 * @param {*}
 * @return {*}
 */
export function industryTree() {
  return request({
    url: '/api/product/industry/industryTree',
    method: 'get'
  })
}

/**
 * @description: 根据行业ID获取行业学习体系数据
 * @param {*} params
 * @return {*}
 */
export function industryLearning(params) {
  return request({
    url: '/api/product/loginIndustry/industryLearning',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 统计地图全国或者省份的园区类型或者荣誉奖项信息数量
 * @param {*} params
 * @return {*}
 */
export function mapStatistics(params) {
  return request({
    url: '/api/product/park/mapStatistics',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 获取园区列表
 * @param {*} params
 * @return {*}
 */
export function getParkList(params) {
  return request({
    url: '/api/product/park/getParkList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 查询荣誉列表数据
 * @param {*} params
 * @return {*}
 */
export function getHonorList(params) {
  return request({
    url: '/api/product/park/getHonorList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 拟办展会信息列表
 * @param {*} params
 * @return {*}
 */
export function proposedExhibitionList(params) {
  return request({
    url: '/api/product/exhibition/proposedExhibitionList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 已办展会信息列表
 * @param {*} params
 * @return {*}
 */
export function doneExhibitionList(params) {
  return request({
    url: '/api/product/exhibition/doneExhibitionList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 拟办展会详情
 * @param {*} params
 * @return {*}
 */
export function proposedExhibitionDetails(params) {
  return request({
    url: '/api/product/exhibition/proposedExhibitionDetails',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 已办展会详情
 * @param {*} params
 * @return {*}
 */
export function doneExhibitionDetails(params) {
  return request({
    url: '/api/product/exhibition/doneExhibitionDetails',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 获取参展企业信息
 * @param {*} params
 * @return {*}
 */
export function exhibitionEnterpriseList(params) {
  return request({
    url: '/api/product/exhibition/exhibitionEnterpriseList',
    method: 'post',
    data: qs.stringify(params),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

/**
 * @description: 获取园区详情信息
 * @param {*} params
 * @return {*}
 */
export function getParkDetails(params) {
  return request({
    url: '/api/product/park/getParkDetails',
    method: 'get',
    params
  })
}

/**
 * @description: 获取园区详情荣誉列表数据
 * @param {*} params
 * @return {*}
 */
export function getHonorDetailsList(params) {
  return request({
    url: '/api/product/park/getHonorDetailsList',
    method: 'get',
    params
  })
}

/**
 * @description: 获取园区企业列表数据
 * @param {*} params
 * @return {*}
 */
export function getParkEnterpriseList(params) {
  return request({
    url: '/api/product/park/getParkEnterpriseList',
    method: 'get',
    params
  })
}
