import { render, staticRenderFns } from "./preExhi.vue?vue&type=template&id=04e63248&scoped=true&"
import script from "./preExhi.vue?vue&type=script&lang=js&"
export * from "./preExhi.vue?vue&type=script&lang=js&"
import style0 from "./preExhi.vue?vue&type=style&index=0&id=04e63248&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04e63248",
  null
  
)

export default component.exports