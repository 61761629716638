<!--
 * @Author: wxb
 * @Date: 2021-07-15 21:43:47
 * @LastEditTime: 2021-08-16 10:54:46
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Subject\preExhi.vue
-->
<template>
  <div class="containner">
    <div class="content">
      <div class="project-search">
        <div class="project-search-input">
          <el-input class="project-search-field"
                    v-model="searchForm.keyword"
                    placeholder="输入关键词搜索..."
                    clearable
                    @keyup.enter.native="searchNotice"></el-input>
          <button type="button"
                  class="project-search-btn"
                  @click="searchNotice">搜索</button>
        </div>
      </div>
      <div class="project-search-senior">
        <div class="project-search-senior-item">
          <div class="project-search-senior-item-title">举办时间：</div>
          <div class="project-search-senior-item-content">
            <div class="notice-type-content">
              <div class="notice-type-content-item"
                   :class="index===activePublishDate ? 'active' : ''"
                   v-for="(item,index) in publishTypeList"
                   :key="index"
                   @click="handleClick4PublishType(index)">{{item}}</div>
              <div class="publish-time">
                <el-date-picker v-model="publishDate"
                                type="daterange"
                                unlink-panels
                                size="small"
                                value-format="yyyy-MM-dd"
                                range-separator="-"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                @change="handlePublishChange">
                </el-date-picker>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="exhibition-list">
        <el-table :data="resultLst"
                  stripe
                  border
                  style="width: 100%"
                  @sort-change="handleSortChanger">
          <template slot="empty">
            <noData tips="哎呀，未查询到相关展会信息"
                    v-show="noData"></noData>
            <viporlogin bgStr="5"
                        :type="viporlogin"
                        v-show="!canSee"></viporlogin>
          </template>
          <el-table-column prop="EXHIBITION_NAME"
                           sortable='custom'
                           label="展会名称">
            <template slot-scope="scope">
              <el-button type="text"
                         size="small"
                         :title="scope.row.EXHIBITION_NAME"
                         @click="handleGoDetail(scope.row.EXHIBITION_SCHE_ID)">{{scope.row.EXHIBITION_NAME}}</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="EXHIBITION_HALL"
                           sortable='custom'
                           label="展馆名称">
          </el-table-column>
          <el-table-column prop="EXHIBITION_ADDRESS"
                           sortable='custom'
                           label="展会地址">
          </el-table-column>
          <el-table-column prop="STARTTIME"
                           sortable='custom'
                           label="开始时间"
                           width="120">
          </el-table-column>
          <el-table-column prop="ENDTIME"
                           sortable='custom'
                           label="结束时间"
                           width="120">
          </el-table-column>
        </el-table>
        <div class="page-panel">
          <el-pagination background
                         layout="prev, slot, next"
                         :total="total"
                         :current-page.sync="currentPage"
                         :hide-on-single-page="true"
                         @current-change="handleCurrentChange">
            <span class="current-page">{{currentPage}}</span>
          </el-pagination>
        </div>
      </div>

      <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
    </div>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import viporlogin from '@/components/viporlogin'
import noData from '@/components/noData'
import dialogviporlogin from '@/components/dialogviporlogin'
import { mapGetters } from 'vuex'
import { dateFormat } from '@/utils/common'
import { proposedExhibitionList } from '@/api/subject'
export default {
  name: 'preExhi',
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  components: {
    viporlogin,
    noData,
    dialogviporlogin
  },
  data() {
    return {
      searchForm: {
        keyword: '',
        starttime: '',
        endTime: '',
        columnName: '',
        order: '',
        start: 0,
        limit: 10
      },
      activePublishDate: 0,
      publishTypeList: ['全部', '未来七天', '未来30天', '未来一年'],
      publishDate: '',
      resultLst: [],
      total: 0,
      currentPage: 1,
      canSee: true,
      viporlogin: 'login',
      noData: false
    }
  },
  methods: {
    // 查询列表
    searchNotice() {
      if (!this.canSee) {
        return
      }
      this.searchForm.start = 0
      this.currentPage = 1
      this.getList()
    },
    // 举办日期选择
    handleClick4PublishType(index) {
      if (!this.canSee) {
        return
      }
      this.activePublishDate = index
      if (index === 0) {
        this.searchForm.starttime = ''
        this.searchForm.endTime = ''
      } else if (index === 1) {
        const end = new Date()
        const start = new Date()
        end.setTime(start.getTime() + 3600 * 1000 * 24 * 7)
        this.searchForm.starttime = dateFormat('YYYY-mm-dd', start)
        this.searchForm.endTime = dateFormat('YYYY-mm-dd', end)
      } else if (index === 2) {
        const end = new Date()
        const start = new Date()
        end.setTime(start.getTime() + 3600 * 1000 * 24 * 30)
        this.searchForm.starttime = dateFormat('YYYY-mm-dd', start)
        this.searchForm.endTime = dateFormat('YYYY-mm-dd', end)
      } else if (index === 3) {
        const end = new Date()
        const start = new Date()
        end.setTime(start.getTime() + 3600 * 1000 * 24 * 365)
        this.searchForm.starttime = dateFormat('YYYY-mm-dd', start)
        this.searchForm.endTime = dateFormat('YYYY-mm-dd', end)
      }
      this.publishDate = ''
      this.searchNotice()
    },
    // 发布时间变化
    handlePublishChange(publishDate) {
      if (!this.canSee) {
        return
      }
      if (publishDate) {
        this.activePublishDate = ''
        this.searchForm.starttime = publishDate[0]
        this.searchForm.endTime = publishDate[1]
      } else {
        this.activePublishDate = 0
        this.searchForm.starttime = ''
        this.searchForm.endTime = ''
      }
      this.searchNotice()
    },
    // 鉴权
    authCheck() {
      const authObj = authenticate('preExhi', this.memberType)
      if (authObj.flag) {
        // 初始化页面
        this.initPage()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    // 初始化页面
    initPage() {
      this.getList()
    },
    getList() {
      proposedExhibitionList(this.searchForm).then(res => {
        if (res.ok) {
          this.resultLst = res.lst
          // 条数限制
          if (res.cnt > 100) {
            this.total = 100
          } else {
            this.total = res.cnt
          }
          if (res.cnt === 0) {
            this.noData = true
          } else {
            this.noData = false
          }
        } else {
          this.$message.error('获取信息异常，请稍后再试')
        }
      }).catch(res => {
        console.log(res)
      })
    },
    // 处理分页
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getList()
    },
    // 排序
    handleSortChanger(prop) {
      this.searchForm.start = 0
      this.currentPage = 1
      if (prop.order) {
        this.searchForm.columnName = prop.prop
        this.searchForm.order = prop.order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.searchForm.columnName = ''
        this.searchForm.order = ''
      }
      this.getList()
    },
    handleGoDetail(id) {
      const authObj = authenticate('preExhi:click', this.memberType)
      if (authObj.flag) {
        const routeData = this.$router.resolve({
          name: 'preExhiDetail',
          params: {
            id
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        this.$refs.dialogviporlogin.handleShow(authObj.msg)
      }
    }
  },
  mounted() {
    if (getToken()) {
      if (this.memberType === 'tourist') {
        this.$store.dispatch('getMemberInfo').then(res => {
          this.authCheck()
        })
      } else {
        this.authCheck()
      }
    } else {
      this.authCheck()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding-left: 1rem;
  margin: auto;

  /deep/ .el-table__empty-text {
    width: 100%;
  }
}

// 搜索框
.project {
  &-search {
    background: #ffffff;
    padding: 0px 50px;
    &-input {
      width: 660px;
      height: 40px;
      display: flex;
      margin: 0 auto;
    }

    &-field {
      flex: 1;
      border: solid 2px #398ce2;
      background-color: #ffffff;
      font-size: 14px;
      font-weight: normal;
      letter-spacing: 0px;
      color: #898a8c;
      /deep/ .el-input__inner {
        border-radius: 0;
        height: 36px;
        line-height: 36px;
      }
    }

    &-btn {
      right: 0;
      width: 56px;
      height: 40px;
      background-color: #398ce2;
      border: 1px solid #398ce2;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0px;
      color: #ffffff;
      cursor: pointer;
    }
  }
}

// 筛选信息
.project {
  &-search-senior {
    background: #ffffff;
    margin: 10px auto;
    padding: 5px 10px;
    &-item {
      display: flex;
      font-size: 14px;
      padding: 18px 0px 10px;
      &:not(:last-child) {
        border-bottom: 1px dashed #e0e0e0;
      }
      &-title {
        width: 100px;
        color: #686868;
        text-align: right;
        padding: 4px 15px 4px 0px;
      }
      &-content {
        flex: 1;

        .notice-type-content {
          display: flex;
          flex-wrap: wrap;
          &-item {
            padding: 4px 7px;
            cursor: pointer;
            margin: 0 2px 10px 0;
            color: #398ce2;
          }
          .active {
            background-color: #398ce2;
            color: #ffffff;
            border-radius: 2px;
          }
        }

        .publish-time {
          margin-top: -5px;
        }
      }
    }
  }
}

.page-panel {
  margin-top: 5px;
  text-align: right;
}
</style>
