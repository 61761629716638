<!--
 * @Author: wxb
 * @Date: 2021-06-29 16:36:05
 * @LastEditTime: 2021-07-18 15:56:34
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\components\viporlogin.vue
-->
<template>
  <div class="nodata">
    <div class="nodata-content"
         :class="{'nodata-content-bg-1':bgStr==='1',
         'nodata-content-bg-2':bgStr==='2',
         'nodata-content-bg-3':bgStr==='3',
         'nodata-content-bg-4':bgStr==='4',
        'nodata-content-bg-5':bgStr==='5'}">
      <div class="nodata-content-tips">
        <el-button type="primary"
                   round
                   v-show="type==='login'"
                   @click="handleClickLogin">登录查看全部</el-button>
        <el-button type="warning"
                   round
                   v-show="type==='vip'"
                   @click="handleOpenVip">开通VIP权益查看全部</el-button>
      </div>
      <div class="nodata-content-vip-login"
           v-show="type==='vip'">已经有vip账号， <a @click="handleClickLogin">去登录</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'viporlogin',
  props: {
    bgStr: {
      type: String,
      default: '1'
    },
    type: {
      type: String,
      defalult: 'login'
    }
  },
  methods: {
    handleClickLogin() {
      localStorage.setItem('sourceurl', this.$route.fullPath)
      this.$router.push({
        name: 'login'
      })
    },
    handleOpenVip() {
      this.$router.push({
        name: 'vip'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.nodata {
  width: 100%;
  margin: 20px auto;
  font-size: 25px;
  text-align: center;
  padding: 40px 0 230px;
  &-content {
    width: 100%;
    height: 44rem;
    margin: 0 auto;
    position: relative;
    &-tips {
      font-size: 1.8rem;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      position: absolute;
      bottom: 44px;
      width: 100%;
    }

    &-vip-login {
      position: absolute;
      bottom: 5px;
      width: 100%;
      font-size: 14px;
      a {
        cursor: pointer;
        color: #409eff;
      }
    }
  }
}

.nodata-content-bg-1 {
  background: url("../assets/Project/originalNotice.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.nodata-content-bg-2 {
  background: url("../assets/Project/finishNotice.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.nodata-content-bg-3 {
  background: url("../assets/Project/builtPro.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.nodata-content-bg-4 {
  background: url("../assets/Project/proposedPro.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.nodata-content-bg-5 {
  background: url("../assets/nodata.png");
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}
</style>
